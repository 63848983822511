import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";


function CGV() { 
    return(
        <div className="landing-page-component">
            <FrontHeader />
            <div className="page-content pb-5 pb-5">
                <h1 className="custom-page-title pt-5">
                    Politique Générale de Ventes <br /> de FLORAFLOW
                </h1>
                <div className="container">
                    <div className="custom-politique-page-content pt-5">
                        <h3 className="custom-sm-title pb-1 pb-1">
                        Politique Générale de Ventes de FLORAFLOW
                        </h3>
                        <div className="content-all-politique pt-4 pb-3">
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-2">
                                    1. Commandes et Paiements
                                </h3>
                                <div className="custom-sm-value">
                                    <div className="content-politique-item">
                                        <h3 className="custom-sm-title pb-1">
                                            1.1. Passer une Commande :
                                        </h3>
                                        <div className="custom-sm-value">
                                            Lorsque vous passez une commande sur notre site, vous acceptez d'acheter les produits ou services sélectionnés aux prix et conditions indiqués.
                                        </div>
                                    </div>
                                    <div className="content-politique-item pt-3">
                                        <h3 className="custom-sm-title pb-1">
                                            1.2. Paiements :
                                        </h3>
                                        <div className="custom-sm-value">
                                            Les paiements sont traités de manière sécurisée via des méthodes de paiement en ligne sécurisées. Les informations de paiement sont cryptées et protégées.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    2. Livraison et Frais de Port
                                </h3>
                                <div className="custom-sm-value">
                                    <div className="content-politique-item">
                                        <h3 className="custom-sm-title pb-1">
                                            2.1. Livraison : 
                                        </h3>
                                        <div className="custom-sm-value">
                                            Nous nous engageons à expédier vos commandes dans les délais indiqués sur notre site. Les délais de livraison peuvent varier en fonction de votre emplacement et du mode d'expédition choisi.
                                        </div>
                                    </div>
                                    <div className="content-politique-item pt-3">
                                        <h3 className="custom-sm-title pb-1">
                                            2.2. Frais de Port : 
                                        </h3>
                                        <div className="custom-sm-value">
                                            Les frais de port sont calculés en fonction du poids, de la taille et de la destination de votre commande. Les frais de port seront indiqués avant la livraison.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    3. Politique de Retour et de Remboursement
                                </h3>
                                <div className="custom-sm-value">
                                    <div className="content-politique-item">
                                        <h3 className="custom-sm-title pb-1">
                                            3.1. Retours : 
                                        </h3>
                                        <div className="custom-sm-value">
                                            Si vous n'êtes pas entièrement satisfait de votre achat, vous pouvez nous retourner les produits dans leur état d'origine dans 
                                            les <strong>24 heures</strong> suivant la réception pour un remboursement ou un échange.
                                        </div>
                                    </div>
                                    <div className="content-politique-item pt-3">
                                        <h3 className="custom-sm-title pb-1">
                                            3.2. Remboursements : 
                                        </h3>
                                        <div className="custom-sm-value">
                                        Les remboursements seront effectués dans les <strong>24 heures</strong> maximum suivant la réception des 
                                        produits retournés, selon le mode de paiement original.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    4. Garantie
                                </h3>
                                <div className="custom-sm-value">
                                    <div className="content-politique-item">
                                        <h3 className="custom-sm-title pb-1">
                                            4.1. Garantie des Produits : 
                                        </h3>
                                        <div className="custom-sm-value">
                                            Nous garantissons que nos produits sont exempts de défauts de matériaux et de fabrication. Cependant, la garantie varie suivant le produit commandé. Si un produit est défectueux, veuillez nous contacter dans les délais annoncés plus haut pour obtenir un remplacement ou un remboursement.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    5. Responsabilité
                                </h3>
                                <div className="custom-sm-value">
                                    <div className="content-politique-item">
                                        <h3 className="custom-sm-title pb-1">
                                            5.1. Limitation de Responsabilité : 
                                        </h3>
                                        <div className="custom-sm-value">
                                            Limitation de Responsabilité : Nous déclinons toute responsabilité pour tout dommage direct, indirect, spécial, consécutif ou punitif résultant de l'utilisation de nos produits ou services. 
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-1">
                                    6. Modifications de la Politique
                                </h3>
                                <div className="custom-sm-value">
                                    Nous nous réservons le droit de modifier cette Politique à tout moment. Les modifications seront effectives dès leur publication sur notre site.
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-1">
                                    7. Contact
                                </h3>
                                <div className="custom-sm-value">
                                    Pour toute question concernant notre Politique Générale de Ventes, veuillez nous contacter via les coordonnées fournies sur notre site.
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <FrontFooter />
        </div>
    )
}

export default CGV